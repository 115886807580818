<template>
  <div>
    <CRow v-if="!loading">
      <CCol lg="12">
        <h1 class="main-header" v-translate translate-context="plan_category">
          Plan Category List
        </h1>
        <CCard class="">
          <CCardHeader>
            <div class="card-header-actions">
              <router-link :to="{name: 'plan_category.new', params: {region: regionId}}" v-if="hasPerm('admin.plan_category.create')">
                <CButton block color="primary" size="sm" v-translate translate-context="list.actions">Create</CButton>
              </router-link>
            </div>
          </CCardHeader>
          <CCardBody class="px-0">
            <AjaxTable
                :fields="fields"
                :data="categories"
                :fetch="fetchCategoryList"
            >
              <template #actions="{item}">
                <td class="text-right">
                  <CButton size="sm" variant="outline" color="primary" class="mr-2"
                           v-if="hasPerm('admin.base_plan.list')"
                           @click="$router.push({name: 'base_plan.list', params: {region: regionId, category: item.id}})"
                           v-translate translate-context="list.actions"
                  >
                    Base Plans
                  </CButton>
                  <CButton size="sm" variant="outline" color="primary" class="mr-2"
                           v-if="hasPerm('admin.plan_category.update')"
                           @click="$router.push({name: 'plan_category.edit', params: {region: regionId, id: item.id}})"
                           v-translate translate-context="list.actions"
                  >
                    Edit
                  </CButton>
                  <CButton @click="onDelete(item.id)" size="sm" variant="outline" color="danger"
                           v-if="hasPerm('admin.plan_category.delete')"
                           v-translate translate-context="list.actions"
                  >
                    Delete
                  </CButton>
                </td>
              </template>
            </AjaxTable>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import AjaxTable from "@/domain/core/components/AjaxTable.vue";

export default {
  name: 'ListPlanCategory',
  components: {AjaxTable},
  data() {
    return {
      loading: true,
      fields: [
        {key: 'id', label: this.$pgettext('plan_category.list', 'ID'), _style: 'width:100px;'},
        {key: 'name', label: this.$pgettext('plan_category.list', 'Name')},
        {key: 'is_active', label: this.$pgettext('plan_category.list', 'Active')},
        {key: 'is_public', label: this.$pgettext('plan_category.list', 'Public')},
        {key: 'actions', label: this.$pgettext('plan_category.list', 'Actions'), sorter: false, filter: false}
      ],
    }
  },
  async mounted() {
    this.loading = true
    this.setRegionId(this.$route.params.region)
    await this.fetchConfig({type: 'admin'})
    this.loading = false
  },
  computed: {
    ...mapGetters('region', [
      'categories',
      'regionId'
    ]),
  },
  methods: {
    ...mapActions('region', [
      'fetchCategoryList',
      'deleteCategory',
      'setRegionId'
    ]),
    ...mapActions('login', [
      'fetchConfig'
    ]),
    onDelete(id) {
      this.confirm({
        title: 'Are your sure?',
        message: 'This action is irreversible',
        cb_confirm: async () => {
          await this.deleteCategory(id)
        }
      })
    }
  }
}
</script>